import axios from "axios";
import React, { useEffect, useState } from "react";
import { config } from "./config";
import { useQuery } from "react-query";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { UpdateImageContext } from "./context/updateImage";
import NavBar2 from "./components/nav-bar2/navBar2";
import HomePage from "./pages/homePage/homePage";
import SignIn4 from "./pages/signin/signin4";
import SignUp from "./pages/signup/signup";
import Category from "./pages/category/category";
import Company from "./pages/company/company";
import ProductDetails from "./pages/productDetails/productDetails";
import CheckOut from "./pages/checkOut/checkOut";
import SucceededPage from "./pages/succeededPage/succeededPage";
import SearchPage from "./pages/search/search";
import PdfComponent from "./components/pdfInvoice/pdfInvoice";
import Policies from "./pages/policies/policies";
import SearchMobilePage from "./pages/searchMobile/searchMobile";
import DefaultLayout from "./layout/DefaultLayout";
import Statistcs2 from "./pages/Dashboard/Statistics";
import Orders from "./components/orders/orders";
import Shipping from "./components/shipping/shipping";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./components/profile/profile";
import SucceededPage2 from "./pages/succeededPage2/succeededPage2";
import Competetions from "./components/competetions/competetions";
import CompetitionDetails from "./components/competetionDetails/competetionDetails";
import Blogs from "./components/blogs/blogs";
import BlogDetails from "./components/blogDetails/blogDetails";

const fetchCategories = async () => {
  const response = await axios.get(config.baseURL + "Categories/getCategories");
  return response.data.map((category) => ({
    ...category,
    image: `data:${category.image_type};base64,${category.image}`,
  }));
};

const fetchProductsForCategory = async (categoryId) => {
  const response = await axios.get(
    config.baseURL + `Products/getProductsForHomePage?id=${categoryId}`
  );
  return response.data;
};

const MainContent = () => {
  const [updateImage, setUpdateImage] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Ensure React Query context is set up properly
  const { data: categories, isLoading: categoriesLoading } = useQuery(
    "categories",
    fetchCategories,
    {
      staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
    }
  );

  const { data: products, isLoading: productsLoading } = useQuery(
    ["products", selectedCategory?.id],
    () => fetchProductsForCategory(selectedCategory?.id),
    {
      enabled: !!selectedCategory, // Fetch products only when a category is selected
    }
  );

  const location = useLocation(); // Call useLocation directly here

  const hideNavBarRoutes = [
    "/signIn",
    "/signup",
    "/myProfile",
    "/orders",
    "/shipping",
    "/succeededPage",
    "/succeededPage2",
    "/competetions",
    "/settings",
    "/blogs"
  ];
  // Check if the current route should hide the NavBar
  const shouldHideNavBar =
    hideNavBarRoutes.includes(location.pathname) ||
    location.pathname.startsWith("/competitions/") || location.pathname.startsWith("/blogs/");

  useEffect(() => {
    if (categories && categories.length > 0) {
      setSelectedCategory(categories[0]); // Set the first category as default
    }
  }, [categories]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <UpdateImageContext.Provider value={{ updateImage, setUpdateImage }}>
      <div style={{ backgroundColor: "black" }}>
        {!shouldHideNavBar && <NavBar2 categories={categories} />}
        <Routes>
          <Route
            path="/"
            element={
              <HomePage
                categories={categories}
                handleCategoryClick={handleCategoryClick}
                products={products}
                selectedCategory={selectedCategory}
              />
            }
          />
          <Route path="/signIn" element={<SignIn4 />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/company/:id" element={<Company />} />
          <Route path="/productDetails/:id" element={<ProductDetails />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/succeededPage" element={<SucceededPage />} />
          <Route path="/succeededPage2" element={<SucceededPage2 />} />
          <Route
            path="/search/:category/:productName"
            element={<SearchPage />}
          />
          <Route path="/test" element={<PdfComponent />} />
          <Route path="/policies" element={<Policies />} />
          <Route
            path="/searchMobile/:productName"
            element={<SearchMobilePage />}
          />
          <Route element={<DefaultLayout />}>
            <Route path="/myProfile" element={<Statistcs2 />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/settings" element={<Profile />} />
            <Route path="/competetions" element={<Competetions />} />
            <Route path="/competitions/:id" element={<CompetitionDetails />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<BlogDetails />} />
          </Route>
        </Routes>
        <ToastContainer autoClose={5000} />
      </div>
    </UpdateImageContext.Provider>
  );
};

export default MainContent;
