import React from "react";
import "./competetionCard.css";

const CompetitionCard = ({ competition }) => {
  const getCompetitionStatus = () => {
    const now = new Date();
    const start = new Date(competition.DateFrom);
    const end = new Date(competition.DateTo);

    if (now < start) return "upcoming";
    if (now > end) return "ended";
    return "active";
  };

  const status = getCompetitionStatus();

  return (
    <div className="competition-card" data-status={status}>
      <span className="status-badge">{status}</span>
      <div className="card-image-wrapper">
        <img
          className="card-image"
          src={`data:${competition.photoType};base64,${competition.image}`}
          alt={competition.photoName}
        />
      </div>
      <div className="card-content">
        <h3 className="competition-title">{competition.title}</h3>

        <div className="date-range">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM5 8V6h14v2H5z" />
          </svg>
          <span>
            {new Date(competition.dateFrom).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            })}{" "}
            -{" "}
            {new Date(competition.dateTo).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </span>
        </div>
        {competition.type === "public" ? (
          <div className="prize-list">
            <div className="prize-item">
              <span>🥇</span>
              {competition.firstPrize}
            </div>
            <div className="prize-item">
              <span>🥈</span>
              {competition.secondPrize}
            </div>
            <div className="prize-item">
              <span>🥉</span>
              {competition.thirdPrize}
            </div>
          </div>
        ) : null}

        <a
          className="view-details-btn"
          href={`/competitions/${competition.id}`}
        >
          عرض التفاصيل
        </a>
      </div>
    </div>
  );
};

export default CompetitionCard;
