// CompetitionDetails.js
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./competetionDetails.css";
import { config } from "../../config";
import axios from "axios";

const CompetitionDetails = () => {
  const { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const navigate = useNavigate();
  const [result, setResult] = useState([]);

  const getStatusBadge = () => {
    const now = new Date();
    const start = new Date(competition.DateFrom);
    const end = new Date(competition.DateTo);

    let status = "";
    let gradient = "";

    if (now < start) {
      status = "قادم";
      gradient = "linear-gradient(90deg, #ff9500 0%, #ffd426 100%)";
    } else if (now > end) {
      status = "منتهي";
      gradient = "linear-gradient(90deg, #8e8e93 0%, #d1d1d6 100%)";
    } else {
      status = "نشط";
      gradient = "linear-gradient(90deg, #4cd964 0%, #5ac8fa 100%)";
    }

    return (
      <div className="status-badge" style={{ background: gradient }}>
        {status}
      </div>
    );
  };

  useEffect(() => {
    // Fetch competition details by ID from API
    // For now using sample data
    const fetchData = async () => {
      const response = await axios.get(
        config.baseURL + "Competetions/getCompetetion",
        {
          params: {
            id: +id,
          },
        }
      );
      setCompetition(response.data);

      axios
        .get(config.baseURL + "Competetions/getCompetetionResult", {
          params: {
            id: +id,
          },
        })
        .then((res) => {
          setResult(res.data);
        });
    };
    fetchData();
  }, [id]);

  if (!competition) return <div>Loading...</div>;

  console.log(competition);

  return (
    <div className="competition-details">
      <button className="back-button" onClick={() => navigate("/competetions")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="white"
        >
          <path d="M14 7l-5 5 5 5V7z" />
          <path fill="none" d="M24 0v24H0V0h24z" />
        </svg>
        العودة إلى القائمة
      </button>

      <div className="details-header">
        <img
          className="hero-image"
          src={`data:${competition.image_type};base64,${competition.image}`}
          alt={competition.image_name}
        />
        <div className="hero-overlay">
          {getStatusBadge()}
          <h1 className="competition-title">{competition.title}</h1>
        </div>
      </div>

      <div className="meta-info">
        <div className="meta-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#6a11cb"
            width="24"
            height="24"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            <path d="M13 7h-2v6h6v-2h-4z" />
          </svg>
          <span>النوع: {competition.type === "public" ? "عام" : "خاص"}</span>
        </div>
      </div>

      <div className="meta-info">
        <div className="meta-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#6a11cb"
          >
            <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM5 8V6h14v2H5z" />
          </svg>
          <span>
            تاريخ البدء:{" "}
            {new Date(competition.dateFrom).toLocaleDateString("ar-EG")}
          </span>
        </div>
        <div className="meta-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#6a11cb"
          >
            <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM5 8V6h14v2H5z" />
          </svg>
          <span>
            تاريخ الانتهاء:{" "}
            {new Date(competition.dateTo).toLocaleDateString("ar-EG")}
          </span>
        </div>
      </div>

      <div className="description-section">
        <h2>تفاصيل المسابقة</h2>
        <p>{competition.description}</p>
      </div>

      {competition.type === "private" &&
        competition.products &&
        competition.products.length > 0 && (
          <div className="products-section">
            <h2>المنتجات المشمولة في المسابقة</h2>
            <div className="products-grid">
              {competition.products.map((product, index) => (
                <div key={index} className="product-card">
                  <span className="product-badge">#{index + 1}</span>
                  {product}
                </div>
              ))}
            </div>
          </div>
        )}

      {competition.type === "public" ? (
        <div className="prizes-section">
          <div className="prize-card">
            <div className="prize-rank">🥇</div>
            <h3>الجائزة الأولى</h3>
            <p>{competition.firstPrize}</p>
          </div>
          <div className="prize-card">
            <div className="prize-rank">🥈</div>
            <h3>الجائزة الثانية</h3>
            <p>{competition.secondPrize}</p>
          </div>
          <div className="prize-card">
            <div className="prize-rank">🥉</div>
            <h3>الجائزة الثالثة</h3>
            <p>{competition.thirdPrize}</p>
          </div>
        </div>
      ) : null}

      <div dir="ltr" className="sports-table-container">
        <h1 className="sports-table-title">ALL-TIME TOP MARKETERS</h1>

        <div className="sports-table">
          <div className="table-header">
            <div className="header-cell rank">RANK</div>
            <div className="header-cell name">NAME</div>
            <div className="header-cell">
              {competition.type === "public" ? "ORDERS" : "ORDERS TOTAL"}
            </div>
          </div>

          {result.map((user, index) => (
            <div
              className={`table-row ${index % 2 === 0 ? "even" : "odd"}`}
              key={index}
            >
              <div className="cell rank-cell">{index + 1}</div>
              <div className="cell name-cell">
                <img
                  src={`data:${user.image_type};base64,${user.image}`}
                  alt={user.name}
                  className="player-logo"
                />
                {user.name}
              </div>
              <div className="cell stat-cell">{user.orderCount}</div>
              {/* <div className="cell stat-cell">{player.games}</div>
            <div className="cell stat-cell highlight">{player.goals}</div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompetitionDetails;
