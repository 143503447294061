import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import axios from "axios";
import { config } from "../../config";
import { toast } from "react-toastify";

const Profile = () => {
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [DBUser, setDBUser] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      //console.error("Invalid token:", error);
    }
  } else {
    //console.log("No token found");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", +user.userId);
      formData.append("name", DBUser.name.toString());
      formData.append("phone", DBUser.phone.toString());
      formData.append("financial", DBUser.financial.toString());
      if (photo) formData.append("photo", photo);

      const response = await axios.post(
        config.baseURL + "Marketers/editMarketer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        // console.log("here");
        toast.success("تم التعديل بنجاح");
        setIsLoading(false);
        setFlag(!flag);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    axios
      .get(config.baseURL + "Marketers/getMarketer", {
        params: {
          id: +user.userId,
        },
      })
      .then((res) => setDBUser(res.data[0]));
  }, [user.userId, flag]);

  return (
    <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
      <div className="flex flex-col gap-9">
        {/* <!-- Input Fields --> */}

        {photo ? (
          <img src={imagePreview}  className="w-full h-40" alt="FFF" />
        ) : (
          <img
            src={`data:${DBUser?.image_type};base64,${DBUser?.image}`}
            alt="FFF"
            className="w-full h-30"
          />
        )}
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
            <h3 className="font-medium text-black dark:text-white">
              Personal Information
            </h3>
          </div>
          <div className="flex flex-col gap-5.5 p-6.5">
            <div>
              <label className="mb-3 block text-black dark:text-white">
                Name
              </label>
              <input
                type="text"
                placeholder="Name"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                value={DBUser?.name}
                onChange={(e) =>
                  setDBUser({
                    ...DBUser,
                    name: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="mb-3 block text-black dark:text-white">
                E-Wallet
              </label>
              <input
                type="text"
                placeholder="E-Wallet"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                value={DBUser?.financial}
                onChange={(e) =>
                  setDBUser({
                    ...DBUser,
                    financial: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="mb-3 block text-black dark:text-white">
                Phone
              </label>
              <input
                type="text"
                placeholder="Phone"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                value={DBUser?.phone}
                onChange={(e) =>
                  setDBUser({
                    ...DBUser,
                    phone: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="mb-3 block text-black dark:text-white">
                Logo
              </label>
              <input
                type="file"
                className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:py-3 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
                accept="image/*"
                onChange={(e) => {
                  setPhoto(e.target.files[0]);
                  if (e.target.files[0]) {
                    const reader = new FileReader();
                    reader.onload = () => {
                      setImagePreview(reader.result); // Set the image URL to state
                    };
                    reader.readAsDataURL(e.target.files[0]); // Read the file as a Data URL
                  }
                }}
              />
            </div>

            <button
              type="submit"
              className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              onClick={handleSubmit}
              disabled={isLoading} // Disable button during loading
            >
              {isLoading ? (
                <svg
                  className="w-5 h-5 mx-auto animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
