import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BlogDetails = () => {
  const location = useLocation();

  const blog = location.state?.blog;
  const navigate = useNavigate();

  if (!blog) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            Blog not found
          </h1>
          <button
            onClick={() => navigate("/blogs")}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Go to Home
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-indigo-50 p-8">
      <div className="max-w-6xl mx-auto bg-white rounded-2xl shadow-2xl hover:shadow-[0_20px_50px_rgba(0,_0,_0,_0.07)] transition-all duration-500 ease-out overflow-hidden group isolate">
        {/* Floating Background Layer */}
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(at_top_right,_var(--tw-gradient-stops))] from-blue-100/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

        {/* Image Section with Parallax Effect */}
        <div className="relative h-[400px] overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent" />
          <img
            src={`data:${blog.image_type};base64,${blog.image}`}
            alt={blog.Name}
            className="w-full h-full scale-110 group-hover:scale-100 transition-transform duration-[800ms] ease-[cubic-bezier(0.22,0.61,0.36,1)]"
          />
        </div>

        {/* Content Container */}
        <div className="p-10 space-y-8 backdrop-blur-sm">
          {/* Back Button with Magnetic Effect */}
          <div className="relative w-fit hover:-translate-x-1 active:translate-x-0 transition-transform">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center gap-2 text-indigo-600 hover:text-indigo-800 font-medium text-lg"
            >
              <span className="relative before:absolute before:-bottom-1 before:h-[2px] before:w-0 before:bg-indigo-600 before:transition-all before:duration-300 hover:before:w-full">
                Back to Blogs
              </span>
              <svg
                className="w-6 h-6 rtl:rotate-180 motion-safe:animate-bounce-x"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
            </button>
          </div>

          {/* Animated Title */}
          <h1 className="text-5xl font-black text-right bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-indigo-600 leading-tight hover:bg-gradient-to-l transition-all duration-500">
            {blog.Name}
          </h1>

          {/* Content with Staggered Animation */}
          <div className="space-y-6">
            <p
              dir="rtl"
              className="text-2xl text-right text-slate-700 leading-relaxed rtl:leading-loose border-r-4 border-indigo-100 pr-6 hover:border-indigo-300 transition-all duration-300"
            >
              {blog.Description.split("\n").map((line, index) => {
                // Check if the line starts with a number and a dash (e.g., "1- ", "2- ")
                const match = line.match(/^(\d+)-\s+(.*)/);
                if (match) {
                  // Extract the item text (e.g., "Item one")
                  const itemText = match[2];
                  return (
                    <ol
                      key={index}
                      dir="rtl"
                      className="text-right list-decimal list-inside"
                    >
                      <li>{itemText}</li>
                    </ol>
                  );
                }
                // For non-list lines, return the line as-is
                return <div key={index}>{line}</div>;
              })}
            </p>

            {/* Decorative Elements */}
            <div className="flex justify-end space-x-4 opacity-70 hover:opacity-100 transition-opacity">
              <div className="w-12 h-1 bg-gradient-to-r from-indigo-400 to-pink-400 rounded-full" />
              <div className="w-8 h-1 bg-gradient-to-r from-pink-400 to-amber-400 rounded-full" />
              <div className="w-4 h-1 bg-gradient-to-r from-amber-400 to-indigo-400 rounded-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
