import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { config } from "../../config";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios.get(config.baseURL + "Blogs/getAllBlogs").then((res) => {
      setBlogs(res.data);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">
        احدث المقالات
      </h1>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogs.map((blog) => (
          <Link
            to={`/blogs/${blog.id}`}
            state={{ blog }}
            key={blog.id}
            className="group bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 ease-[cubic-bezier(0.4,0,0.2,1)] hover:-translate-y-2 relative overflow-hidden border border-gray-100"
          >
            <div className="relative overflow-hidden">
              <img
                src={`data:${blog.image_type};base64,${blog.image}`}
                alt={blog.Name}
                className="w-full h-60  transform transition-transform duration-500 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/30 to-transparent" />
            </div>

            <div className="p-6 text-right space-y-3">
              <h2 className="text-2xl font-bold text-gray-800 mb-2 transition-colors group-hover:text-blue-600">
                {blog.Name}
              </h2>

              <div className="relative">
                <p className="text-gray-600 line-clamp-2 text-lg leading-relaxed">
                  {blog.Description}
                </p>
                <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white via-white/80 to-transparent" />
              </div>

              <button className="mt-4 px-6 py-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl hover:shadow-lg transition-all duration-300 hover:translate-x-2 hover:scale-[1.02] font-semibold flex items-center justify-end gap-2 ml-auto">
                عرض التفاصيل
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
