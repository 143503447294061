import React, { useContext } from "react";
import "./productCard.css";
import PrimaryButton from "../button/primaryButton";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/cart/actions";
import { useTranslation } from "react-i18next";
import { UpdateImageContext } from "../../context/updateImage";

const ProductCard = ({
  name,
  price,
  fakePrice,
  suggestedPrice,
  outOfStock,
  image,
  image_type,
  id,
  addToCart,
  removeFromCart,
  isInCart,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { updateImage, setUpdateImage } = useContext(UpdateImageContext);

  return (
    <div
      className="max-w-sm bg-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mr-3 product-card"
      style={{
        margin: "auto",
        backgroundColor: "#222222",
        color: "white",
        boxShadow:
          "0 6px 5px 0 rgba(217, 28, 28, 0.1), 0 6px 5px 0 rgba(217, 28, 28, 0.3)",
        position: "relative",
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          top: "29px",
          right: "-30px",
          backgroundColor: "#FFD700",
          color: "#000",
          padding: "4px 20px",
          textAlign: "center",
          transform: "rotate(45deg)",
          boxShadow: "0 2px 4px rgba(255, 215, 0, 0.3)",
          fontSize: "14px",
          fontWeight: "bold",
          zIndex: 2,
          clipPath: "polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%, 10% 50%)",
        }}
      >
        <span style={{ display: "block", transform: "rotate(-5deg)" }}>
          رمضان كريم
        </span>
        <div
          style={{
            position: "absolute",
            bottom: "-6px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "20px",
            height: "10px",
            backgroundColor: "#FFD700",
            borderRadius: "50%",
            filter: "brightness(0.9)",
          }}
        />
      </div> */}
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/productDetails/${id}`}
      >
        <div>
          <img
            className="rounded-t-lg"
            src={`data:${image_type};base64,${image}`}
            alt={name}
            style={{ height: "200px", objectFit: "cover", margin: "0 auto" }}
          />
        </div>
      </Link>

      {outOfStock && (
        <div
          style={{
            position: "absolute",
            top: fakePrice > 0 ? "50px" : "10px",
            left: "10px",
            backgroundColor: "#D91C1C",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "14px",
            zIndex: 1,
          }}
        >
          غير متاح
        </div>
      )}

      {fakePrice > 0 && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            backgroundColor: "#D91C1C",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "14px",
            zIndex: 1,
          }}
        >
          {Math.round(((fakePrice - price) / fakePrice) * 100)}% OFF
        </div>
      )}
      <div className="p-5">
        <div className="flex text-right flex-col gap-2 mb-2">
          {fakePrice > 0 ? (
            <p className="text-lg font-bold text-red-500 line-through">
              {String(fakePrice).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EGP
            </p>
          ) : null}

          <p className="mb- text-lg  text-white-700 dark:text-white-400">
            {String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EGP
          </p>

          {suggestedPrice > 0 ? (
            <p
              dir="rtl"
              className="mb- text-lg  text-white-700 dark:text-white-400"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div> السعر المقترح : </div>
              <span
                style={{
                  background: "linear-gradient(145deg, #fff3b0, #ffd700)",
                  color: "#2d3436",
                  padding: "6px 15px",
                  borderRadius: "25px",
                  border: "2px solid #ffd700",
                  boxShadow: "0 3px 8px rgba(0, 0, 0, 0.15)",
                  transition: "all 0.3s ease",
                  display: "inline-block",
                  marginRight: "8px",
                  fontFamily: "monospace",
                  fontSize: "1.1em",
                  position: "relative",
                  overflow: "hidden",
                  transform: "translateY(0)",
                  cursor: "pointer",
                }}
              >
                {" "}
                {String(suggestedPrice).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}{" "}
                EGP
              </span>
            </p>
          ) : null}
        </div>
        <div>
          <h5 className="text-lg font-bold tracking-tight text-white-900 dark:text-white text-right">
            {name}
          </h5>
        </div>

        {isInCart ? (
          <div
            style={{
              backgroundColor: "#D91C1C",
              cursor: "pointer",
              width: "100%",
            }}
            className="mt-2 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => removeFromCart(id)}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              {t("card_delete_from_cart")}
            </div>
          </div>
        ) : (
          <Link
            to={`/productDetails/${id}`}
            className="mt-2 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            style={{
              backgroundColor: "#D91C1C",
              width: "100%",
              textAlign: "center",
            }}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              {t("card_add_to_cart")}
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isInCart: state.cart.some((item) => item.id === ownProps.id),
});

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
