import React, { useEffect, useRef, useState } from "react";
import "./banner.css"; // External CSS file for styling
import bannerImg from "../../assets/ramadan1.jpg";
import categoryImg from "../../assets/tabler_category.png";
import searchImg from "../../assets/search-line.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { config } from "../../config";

const Banner = () => {
  const [productName, setProductName] = useState("");
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const dropdownRef = useRef(null);

  // Debounce API calls
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (productName.trim()) {
        try {
          const response = await axios.get(
            config.baseURL + "Products/searchProducts",
            {
              params: {
                name: productName,
              },
            }
          );
          setSuggestions(response.data);
          setShowSuggestions(true);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    };

    const debounceTimer = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(debounceTimer);
  }, [productName]);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSuggestionClick = (product) => {
    setProductName(product.name);
    setShowSuggestions(false);
    // Optional: Navigate directly to product page
    // navigate(`/product/${product.id}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setActiveSuggestion((prev) => Math.min(prev + 1, suggestions.length - 1));
    } else if (e.key === "ArrowUp") {
      setActiveSuggestion((prev) => Math.max(prev - 1, 0));
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (suggestions.length > 0) {
        handleSuggestionClick(suggestions[activeSuggestion]);
      }
      navigate(`/searchMobile/${productName}`);
    }
  };

  return (
    <div className="banner-section">
      {/* Banner Section */}
      <div className="banner-content">
        <img src={bannerImg} alt="Banner" className="banner-image" />
        <div className="overlay">
          <h1 className="banner-title">ABD-ELRAHMAN SUPPLEMENTS</h1>
        </div>
      </div>

      {/* Search Section */}
      <div className="search-bar" dir="rtl">
        <div className="search-container" ref={dropdownRef}>
          <input
            type="text"
            className="search-input"
            placeholder="مثال للبحث: واي بروتين حجم 1 كيلو جرام"
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value);
              setActiveSuggestion(0);
            }}
            onKeyDown={handleKeyDown}
            onFocus={() => setShowSuggestions(true)}
          />

          <button
            className="banner-search-button"
            onClick={() => navigate(`/searchMobile/${productName}`)}
          >
            <SearchIcon />
          </button>

          {showSuggestions && suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((product, index) => (
                <li
                  key={product.id}
                  onClick={() => handleSuggestionClick(product)}
                  className={index === activeSuggestion ? "active" : ""}
                >
                  {product.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
