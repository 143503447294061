import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Categories from "../../components/categories/categories";
import Products from "../../components/products/products";
import NewProducts from "../../components/newProducts/newProducts";
import Footer from "../../components/footer/footer";
import Reviews from "../../components/reviews/reviews";
import axios from "axios";
import { config } from "../../config";
import Companies from "../../components/companies/companies";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import Banner from "../../components/banner/banner";
import slider3 from "../../assets/slider3.jpg";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import decoration from "../../assets/decoration3.png";
import "./homePage.css";

const HomePage = ({
  categories,
  products,
  selectedCategory,
  handleCategoryClick,
}) => {
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      //console.error("Invalid token:", error);
    }
  } else {
    //console.log("No token found");
  }

  return (
    <div>
      <Helmet>
        <title>عبدالرحمن ستورز - Adbelrhman Stores</title>
        <meta
          name="description"
          content="Welcome to عبدالرحمن ستورز, the best place for shopping..."
        />
        <meta name="keywords" content="عبدالرحمن, stores, عبدالرحمن ستورز" />
      </Helmet>

      {user ? (
        <div style={{ overflow: "hidden" }}>
          <section className="greeting-section">
            <p>
              <span>
                👋 أهلاً، <strong>{user.userName}</strong>! نتمنى لك تجربة تسوق
                ممتعة.
              </span>
            </p>
          </section>
        </div>
      ) : (
        <div style={{ marginTop: "4rem" }}></div>
      )}

      <Banner categories={categories ? categories : []} />
      <div className="lights-separator">
        <img src={decoration} alt="String Lights" className="lights-image" />
      </div>
      <div className="body">
        <Categories categoriesProp={categories ? categories : []} />
        <Header />
        {/* <Offers /> */}
        <Products
          categories={categories ? categories : []}
          products={products ? products : []}
          selectedCategory={selectedCategory}
          // setProducts={setProducts}
          setSelectedCategory={selectedCategory}
          handleCategoryClick={handleCategoryClick}
        />
        <div className="header_container">
          <div
            className="carousel_container"
            style={{ marginTop: "1rem", borderRadius: "10px" }}
          >
            <img
              style={{ borderRadius: "10px" }}
              className="headerCarousel_img"
              src={slider3}
              alt="عبدالرحمن ستورز"
            />
            {/* <img className="headerCarousel_img" src={slider2} alt="FFF" />
          <img className="headerCarousel_img" src={slider3} alt="FFF" /> */}
          </div>
          {/* <div className="header-rightSide">
        <img src={slider1} alt="FFF" className="header-rightSide_1"/>
        <img src={slider2} alt="FFF" className="header-rightSide_2"/>
      </div> */}
        </div>
        {/* {categories.map((category) => (
          <div key={category.id} className="category-section">
            <CategoryProducts category={category} />
          </div>
        ))} */}
        <NewProducts />
        <Reviews />
        {/* <Advantages /> */}
      </div>

      <div className="lights-separator">
        <img src={decoration} alt="String Lights" className="lights-image" />
      </div>
      <Companies />
      <Footer />
    </div>
  );
};

export default HomePage;
