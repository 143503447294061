import axios from "axios";
import React, { useEffect, useState } from "react";
import { config } from "../../config";
import CompetitionCard from "../competetionCard/competetionCard";
import './competetions.css'

const Competetions = () => {
  const [competetions, setCompetetions] = useState([]);

  useEffect(() => {
    axios
      .get(config.baseURL + "Competetions/getAllCompetetions")
      .then((res) => {
        console.log(res.data);
        setCompetetions(res.data);
      });
  }, []);
  return (
    <div className="competition-grid">
      {competetions.map((comp) => (
        <CompetitionCard key={comp.id} competition={comp} />
      ))}
    </div>
  );
};

export default Competetions;
